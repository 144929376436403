import { Link, graphql } from "gatsby"
import React, { useEffect, useState, useLayoutEffect } from "react"
import { filterByCategory, searchCards, sortCards } from "../../util/helpers"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import SidebarFilters from "../../components/sidebarFilters"
import StackCard from "../../components/card/stackCard"
import StackHeader from '../../components/stackHeader'
import TrainingCardLarge from "../../components/card/trainingCardLarge"
import Filter from '../../images/icons/filter.svg'
import Close from '../../images/icons/closeIcon.svg'
import _ from 'lodash'
import lunr from "lunr"

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', _.debounce(updateSize, 150));
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
const Stacks = ({ data }) => {
  const allCards = [
    ...("allWpSource" in data ? data.allWpSource.edges || [] : []),
    ...("allWpDestination" in data ? data.allWpDestination.edges || [] : []),
    ...("allWpAnalyse" in data ? data.allWpAnalyse.edges || [] : []),
    ...("allWpTraining" in data ? data.allWpTraining.edges || [] : []),
    ...("allWpTutorial" in data ? data.allWpTutorial.edges || [] : [])
  ]
  let groupedData = _.groupBy(allCards, 'node.nodeType');
  const subNav = Object.keys(groupedData);

  const allCategories = [
    ...("allWpAnalyseCategory" in data ? data.allWpAnalyseCategory.edges || [] : []),
    ...("allWpDestinationCategory" in data ? data.allWpDestinationCategory.edges || [] : []),
    ...("allWpSourceCategory" in data ? data.allWpSourceCategory.edges || [] : []),
  ]

  const stackPages = {
    "stacks": data.wp.tenjinSettings.acf.stacksPage,
    "destinations": data.wp.tenjinSettings.acf.destinationsPage,
    "sources": data.wp.tenjinSettings.acf.sourcesPage,
    "analyses": data.wp.tenjinSettings.acf.analysesPage,
    "training": data.wp.tenjinSettings.acf.trainingPage,
    "tutorial": data.wp.tenjinSettings.acf.tutorialPage
  }

  const stackMenuLabels = {
    "stacks": data.wp.tenjinSettings.acf.stacksMenuLabel,
    "destinations": data.wp.tenjinSettings.acf.destinationsMenuLabel,
    "sources": data.wp.tenjinSettings.acf.sourcesMenuLabel,
    "analyses": data.wp.tenjinSettings.acf.analysesMenuLabel,
    "training": data.wp.tenjinSettings.acf.trainingMenuLabel,
    "tutorial": data.wp.tenjinSettings.acf.tutorialMenuLabel
  }

  const filterOptions = [
    { name: "Popular", slug: "popular", id: `1-popular` },
    { name: "Recently Added", slug: "new", id: `2-new` },
    { name: "Upcoming", slug: "upcoming", id: `3-upcoming` },
  ]

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [filter, setFilter] = useState(null)

  const [categoryFilter, setCategoryFilter] = useState(null)

  const [filterList, setFilterList] = useState([])

  const [searchQuery, setSearchQuery] = useState("")

  const [searchIndex, setSearchIndex] = useState(null)

  const [showSidebar, setShowSidebar] = useState(false);

  let cardsToDisplay = [];
  let searchResultCount = null;

  (() => {
    if (searchIndex && searchQuery) {
      cardsToDisplay = searchCards(searchIndex, searchQuery, allCards)
    } else {
      cardsToDisplay = allCards
    }

    cardsToDisplay = filterByCategory(cardsToDisplay, categoryFilter)

    if (filter) {
      sortCards(cardsToDisplay, filter)
    }

    groupedData = _.groupBy(cardsToDisplay, 'node.nodeType')

    searchResultCount = Object.values(groupedData).reduce(((sum, array) => sum + array.length), 0);

  })()

  const handleClose = (value) => {
    if (params.has(value.type)) {
      params.delete(value.type);
    }

    navigate(`${location.pathname}?${params.toString()}`)
  }

  useEffect(() => {
    setSearchIndex(buildSearchIndex())
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const buildSearchIndex = () => {
    return lunr(function () {
      this.ref("id")
      this.field("title")

      this.pipeline.remove(lunr.stemmer)
      this.searchPipeline.remove(lunr.stemmer)

      allCards.map(card => {
        return this.add({
          id: card.node.id,
          title: card.node.title,
        })
      })
    })
  }

  const [cardTitle, setCardTitle] = useState("")

  const [width] = useWindowSize();
  const getLists = (item) => {
    let slice = (item !== `Training` && item !== `Tutorial`) ? 4 : 3;
    if (width < 1540) {
      slice = 3;
    }
    if (width < 1280) {
      slice = 2
    }
    return groupedData[item].slice(0, slice).map((data, index) => {
      switch (item) {
        case 'Training':
        case 'Tutorial':
          return (
            <TrainingCardLarge
              cardData={data}
              key={index}
              setCardTitle={setCardTitle}
              cardTitle={cardTitle}
            ></TrainingCardLarge>
          )
        default:
          return (
            <StackCard
              cardData={data}
              key={index}
            ></StackCard>
          )
      }
    })
  }
  return (
    <Layout>
      <Helmet>
        <body className="trainings-page" />
      </Helmet>
      {data.wp.tenjinSettings.acf.stacksPage &&
        <Seo
          title="Stacks | Growth FullStack"
          post={data.wp.tenjinSettings.acf.stacksPage}
        />
      }
      <StackHeader setSearchQuery={setSearchQuery} {...{ filter, setFilter }} stackPages={stackPages} stackMenuLabels={stackMenuLabels} />
      <div className="flex mx-auto mt-12 page-container lg:max-w-4xl xl:max-w-7xl 2xl:max-w-screen-2xl lg:px-0">
        <div className="hidden w-1 w-1/5 h-1 pr-1 mr-6 lg:block"></div>
        <div className="h-5 mb-2 w-full lg:w-3/4 px-6 lg:px-0">
          {searchQuery.trim().length > 0 && searchResultCount !== null && (
            <p className={`text-xl-v0 text-midnight-tenjin font-medium leading-5`}>
              Showing <span className="font-black">{searchResultCount}</span> {searchResultCount === 1 ? "Result" : "Results"}
            </p>
          )}
        </div>
      </div>
      <div className="mx-auto max-w-screen-2xl pb-25 md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-screen-2xl">
        <div className="flex flex-row px-6 lg:px-0">
          <SidebarFilters
            categories={allCategories}
            filterOptions={filterOptions}
            setCategoryFilter={setCategoryFilter}
            categoryFilter={categoryFilter}
            filter={filter}
            setFilter={setFilter}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            filterList={filterList}
            setFilterList={setFilterList}
          />
          <div className="w-full lg:w-3/4">
            <button
              className='flex items-center flex-shrink-0 py-2 px-3 mt-4 rounded-full shadow-lg text-sm text-white bg-nav-active h-10 lg:hidden cursor-pointer w-max ml-auto'
              onClick={() => { setShowSidebar(true) }}
            >
              <img src={Filter} alt="filter icon" className='mr-1' />
              Filters
            </button>
            <h2 className={`mb-10 font-black text-font-primary text-4xl-m`}>{stackPages['stacks'].title}</h2>
            <div className='flex flex-wrap space-x-2 lg:hidden mb-5'>
              {filterList?.length > 0 && filterList.map(item => {
                return (
                  <div className='flex items-center bg-purpleLight text-primary font-semibold px-4 py-2 rounded space-x-3 mb-2' key={item?.id}>
                    <div className='text-base leading-4 align-middle'>{item?.value}</div>
                    <button
                      onClick={() => handleClose(item)}
                    >
                      <img
                        src={Close}
                        alt="close icon"
                        className='my-auto cursor-pointer'
                      />
                    </button>

                  </div>
                )
              })}
            </div>

            {
              subNav.map((item, index) => {
                return <div key={`${item}-${index}`}>
                  {groupedData[item] ?
                    <div className="mb-20" key={`${item.toLowerCase()}-${index}`}>
                      <div className="flex justify-between">
                        <div className={`mb-10 w-4/5 lg:w-2/3`}>
                          <h3 className={`font-medium text-primary text-3xl leading-8 mb-2`}>
                            {stackPages[`${item.toLowerCase()}${item !== 'Training' && item !== 'Tutorial' ? 's' : ''}`].title}
                          </h3>
                          <span className={`leading-8 font-normal text-2xl text-font-primary stacks-subheader-body opacity-60`} dangerouslySetInnerHTML={{ __html: stackPages[`${item.toLowerCase()}${item !== 'Training' && item !== 'Tutorial'? 's' : ''}`].content }} />
                        </div>
                        {searchQuery.trim().length > 0 && searchResultCount !== null
                          ? <></>
                          : (
                            <Link
                              className="block font-black underline text-primary"
                              to={`${item !== 'Training' && item !== 'Tutorial' ? '/stacks' : ''}${(item === 'Training' ? '/usecase' : (item !== 'Tutorial' ? '/' + item.toLowerCase() + 's' : '/' + item.toLowerCase()))}`}>
                              View All
                            </Link>
                          )}
                      </div>
                      <div className="flex flex-col w-full space-y-20">
                        <div className={`w-full gap-6 ${(item === 'Training' || item === 'Tutorial') ? "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3" : "stack--pages__main-container-stack--layout"}`} >
                          {searchQuery.trim().length > 0 && searchResultCount !== null ? (
                            <>
                              {groupedData[item].map((data, index) => {
                                switch (item) {
                                  case 'Training':
                                  case 'Tutorial':
                                    return (
                                      <TrainingCardLarge
                                        cardData={data}
                                        key={index}
                                        setCardTitle={setCardTitle}
                                        cardTitle={cardTitle}
                                      ></TrainingCardLarge>
                                    )
                                  default:
                                    return (
                                      <StackCard
                                        cardData={data}
                                        key={index}
                                      ></StackCard>
                                    )
                                }
                              })}
                            </>
                          ) : (
                            <>
                              {getLists(item)}
                            </>
                          )}
                        </div>
                      </div>
                    </div> : ''
                  }
                </div>
              })
            }
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allWpTraining(sort: { fields: [menuOrder], order: ASC }) {
      edges {
        node {
          id
          acf {
            tenjinArticleNumber
            tenjinHeadline
            tenjinRelatedContent {
              ... on WpTraining {
                databaseId
                nodeType
                title
                slug
                uri
                acf {
                  tenjinCardExcerpt
                  tenjinArticleNumber
                }
              }
              ... on WpAnalyse {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  iconAlt
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
              ... on WpDestination {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  iconAlt
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
              ... on WpSource {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  iconAlt
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
            }
            tenjinTrainingSteps {
              tenjinStepTitle
              tenjinTrainingStepContent
            }
            tenjinCardExcerpt
            tenjinUpcomingContent
            tenjinNoContent
            tenjinNewContent
            tenjinPopularity
            tenjinPaidContent
          }
          title
          nodeType
          uri
          slug
          date
          categories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
    allWpTutorial(sort: { fields: [menuOrder], order: ASC }) {
      edges {
        node {
          id
          acf {
            tenjinArticleNumber
            tenjinHeadline
            tenjinRelatedContent {
              ... on WpTraining {
                databaseId
                nodeType
                title
                slug
                uri
                acf {
                  tenjinCardExcerpt
                  tenjinArticleNumber
                }
              }
              ... on WpAnalyse {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  iconAlt
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
              ... on WpDestination {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  iconAlt
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
              ... on WpSource {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  iconAlt
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
            }
            tenjinTrainingSteps {
              tenjinStepTitle
              tenjinTrainingStepContent
            }
            tenjinCardExcerpt
            tenjinUpcomingContent
            tenjinNoContent
            tenjinNewContent
            tenjinPopularity
            tenjinPaidContent
          }
          title
          nodeType
          uri
          slug
          date
          categories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
    allWpSource(sort: { fields: menuOrder, order: ASC }) {
      edges {
        node {
          id
          acf {
            iconColor {
              sourceUrl
              mediaDetails {
                height
                width
              }
            }
            iconAlt
            tenjinUpcomingContent
            tenjinNoContent
            tenjinNewContent
            tenjinPopularity
          }
          title
          nodeType
          uri
          slug
          date
          categories: sourceCategories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
    allWpAnalyse(sort: { fields: menuOrder, order: ASC }) {
      edges {
        node {
          id
          acf {
            iconColor {
              sourceUrl
              mediaDetails {
                height
                width
              }
            }
            iconAlt
            tenjinUpcomingContent
            tenjinNoContent
            tenjinNewContent
            tenjinPopularity
          }
          title
          nodeType
          uri
          slug
          date
          categories: analyseCategories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
    allWpDestination(sort: { fields: menuOrder, order: ASC }) {
      edges {
        node {
          id
          acf {
            iconColor {
              sourceUrl
              mediaDetails {
                height
                width
              }
            }
            iconAlt
            tenjinUpcomingContent
            tenjinNoContent
            tenjinNewContent
            tenjinPopularity
          }
          title
          nodeType
          uri
          slug
          date
          categories: destinationCategories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
    allWpAnalyseCategory(filter: { parentId: { eq: null } }) {
      edges {
        node {
          name
          slug
          parentId
          id
          wpChildren {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    allWpDestinationCategory(filter: { parentId: { eq: null } }) {
      edges {
        node {
          name
          slug
          parentId
          id
          wpChildren {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    allWpSourceCategory(filter: { parentId: { eq: null } }) {
      edges {
        node {
          name
          slug
          parentId
          id
          wpChildren {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    wp {
      tenjinSettings {
        acf {
          stacksMenuLabel
          destinationsMenuLabel
          sourcesMenuLabel
          trainingMenuLabel
          tutorialMenuLabel
          analysesMenuLabel
          stacksPage {
            ... on WpPage {
              id
              title
              seo {
                canonical
                cornerstone
                focuskw
                fullHead
                metaDesc
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphAuthor
                opengraphDescription
                opengraphModifiedTime
                opengraphPublishedTime
                opengraphPublisher
                opengraphSiteName
                opengraphTitle
                opengraphType
                opengraphUrl
                readingTime
                title
                twitterDescription
                twitterTitle
              }
            }
          }
          destinationsPage {
            ... on WpPage {
              id
              title
              content
            }
          }
          sourcesPage {
            ... on WpPage {
              id
              title
              content
            }
          }
          analysesPage {
            ... on WpPage {
              id
              title
              content
            }
          }
          trainingPage {
            ... on WpPage {
              id
              title
              content
            }
          }
          tutorialPage {
            ... on WpPage {
              id
              title
              content
            }
          }
        }
      }
    }
  }
`
export default Stacks;
